import * as React from "react"
import { Link } from "gatsby"



function Sidebar() {
  return (
    <div className=" text-white grid grid-cols-2 gap-4 fixed w-screen bg-gray-900 h-16 z-50">
      <Link className="text-xl mx-5 my-5 w-28" to="/">
        <span>KXP | </span> <strong>DOCS</strong>
      </Link>
    </div>
  );
}

export default Sidebar



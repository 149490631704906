import * as React from "react"
import { Link, graphql } from "gatsby"
import TopNavBar from "../components/layout/topNavBar"


const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <div className="flex flex-col ">
      <TopNavBar />
      <div className="m-auto max-w-4xl mt-16 p-5">
        <h1 className="text-2xl">OLD POSTS</h1>
        {posts.map(post => (
          <Link key={post.id} to={post.fields.slug}>
            <div className=" group border hover:bg-white hover:shadow-md   hover: border-8 w-auto p-4 my-7  ">
              <h1>{post.frontmatter.title}</h1>
              <p className="text-xs" >{post.excerpt}</p>
            </div>
          </Link>
        )
        )}
      </div>
    </div >
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
